export class Response{
    constructor(){
    }

    handle(response){
        let responseData = response.data

        this.status = responseData.status
        this.redirect = responseData.redirect
        this.msg = responseData.msg
        this.data = responseData.data

        if(this.status==true && this.redirect!=null){
            this.redirectTo(this.redirect);
            return false
        }else if(this.status==true && this.msg!=null){
            responseData.msgType = 'success'
            return responseData
        }else if(this.status==false && this.msg!=null && this.redirect==null){
            responseData.msgType = 'error'
            return responseData
        }else if(this.status==false && this.msg!=null && this.redirect!=null){
            this.redirectTo(this.redirect);
            responseData.msgType = 'error'
            return responseData
        }

        return responseData
    }

    errorHandle(response){
        let error = {
            msgType : null,
            msg : null
        }
        let status = response.status

        switch (status) {
            case 400 :
                error.msgType = 'error'
                error.msg = 'Server connection error!'
                return error
            case 500 :
                error.msgType = 'error'
                error.msg = 'Server code base error, please contact with us!'
                return error
            default:
                error.msgType = 'error'
                error.msg = 'Error code: '+status+'<br>Please contact with us!'
                return error
        }
    }

    redirectTo(redirect){
        window.location.assign(redirect);
    }
}