import { createRouter, createWebHistory } from 'vue-router'

import Game from '../views/Game'


const routes = [
  {
    path: '/',
    name: 'game',
    component: Game
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: createWebHistory('/'),
  routes
})

export default router
