<template>
  <Welcome v-if="status==='welcome'" :initData="initData" :contents="contents"></Welcome>
  <Question v-if="status==='question'" :initData="initData" :contents="contents"></Question>
  <Outro v-if="status==='end'" :initData="initData" :contents="contents"></Outro>
</template>

<script>
import Welcome from "@/views/Welcome"
import Question from "@/views/Question"
import Outro from "@/views/Outro"
import {Response} from "@/classes/response";

export default {
  name: 'Game',
  props: ['initData','contents'],
  components: {
    Welcome, Question, Outro
  },
  data(){


    return {

    }
  },
  computed:{
    status(){
      return this.initData.user.status
    }
  },
  methods:{
    restart(e){
      e.initData.loader = true
      let postData = {}

      e.$axios.post('/api/v1/user/restart',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);
              sessionStorage.removeItem('challengeon_timer');
              e.initData.user.status = 'welcome'
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });
    },
    logOut(e){
      e.initData.loader = true
      let postData = {}

      e.$axios.post('/api/v1/user/logOut',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              localStorage.removeItem('challeng_session_hash')
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });
    }
  }
}
</script>
<style>
</style>
