<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-body">
            <h1>Selejtező teljesítve.</h1>
            <div>
              <p>Ügyesek vagytok, túl is vagytok a selejtezőn. Köszönjük, hogy időt szántatok a feladatsor kitöltésére.</p>
              <p>Most nincs más feladatotok, mint megvárni az eredmények összesítését, reméljük sikerült bejutnotok a legjobb 20 csapat közé.</p>
              <p>Hamarosan jelentkezünk az eredményekkel, igyekszünk október 13-án kihirdetni az eredményeket.</p>
              <p>Hamarosan találkozunk!<br>Challenge.ON 2023 szervezői csapata</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Outro',
  props: ['initData','contents'],
  data(){
    this.initData.bigImage = 2
    return{

    }
  },
  computed:{

  },
  methods:{

  }

}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,0.9);
}
</style>
