<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-body">
            <h1>Szia {{firstName}},</h1>
            <div>
              <p>köszöntünk a Challenge.ON selejtezőjében!</p>
              <p>Mit gondoltok, hogyan segíthet a mesterséges intelligencia és az IoT technológia a villamosenergia elosztóhálózatok rugalmasságának növelésében?</p>
              <p>A villamosenergiarendszerben átalakulóban vannak a fogyasztói és termelői trendek. Egyre nő a fogyasztás, mindeközben pedig megjelentek a háztartási méretű kiserőművek (pl. napelemek), amelyek hatására változik a teljesítményáramlás iránya és ingadozik a feszültség. A meglévő hálózat jelenlegi struktúrájában nem felel meg az új kihívásoknak. Az AI és az IoT technológia rohamos fejlődése azonban új lehetőségeket teremt arra, hogy a szakemberek számára új műszaki megoldások is megvalósíthatóak legyenek.</p>
              <p>Az idei Challenge.ON versenyen ezt a témát járjuk körbe. Már a selejtező során megismeritek a legfontosabb kérdésköröket. Lássuk hát, mit tartogat a következő 28 kérdés!</p>
              <h2>Fontos tudnivalók</h2>
              <ul>
                <li>A selejtezőt csapat szinten oldjátok meg, tehát nem kell minden csapattagnak bejelentkeznie, és megválaszolnia a kérdéseket.</li>
                <li><strong>Ha másik csapattag is belép, akkor ott folytatja, ahol a másik abbahagyta, de csak az első mentést vesszük figyelembe!</strong></li>
                <li>A selejtező időtartama alatt bármikor meg lehet szakítani, és folytatni a megoldást.</li>
                <li>A kérdések között nem lehet lépkedni, tehát ha megválaszoltatok egy kérdést, akkor azt később nem tudjátok módosítani, így figyelmesen töltsétek ki.</li>
                <li>Kérdésenként egy vagy több jó válasz is lehetséges.</li>
                <li>Az "Elkezdjük a selejtezőt" gombra kattintástól mérjük a kitöltési gyorsaságot az utolsó kérdés megválaszolásáig.</li>
                <li>Érdemes a kérdésekre időt szánni, mivel a kérdéskörök a verseny további szakaszában is vissza fognak köszönni.</li>
              </ul>
              <h2>Pontozás és továbbjutás</h2>
              <p>A kérdések egyenlő súllyal számítanak az összpontszámban. A 20 legtöbb pontot elérő csapat jut tovább a következő fordulóba. Pontegyezőség esetén a kitöltés gyorsasága fogja eldönteni a sorrendet. Fontos, hogy csak azon eredményeket vesszük figyelembe, ahol az összes kérdést megválaszolták.</p>
              <h2>Időzítés</h2>
              <p>A selejtező időtartama: október 12. 16:00-22:00, bármikor elkezdhetitek az adott időablakban, de be kell fejezni 22:00-ra.</p>
              <p>22:00 után már nem fogadunk el válaszokat.</p>
              <p>A selejtező megoldására minimum fél órára van szükség, így ne hagyjátok az utolsó pillanatra.</p>
              <p>Jó játékot kívánunk!<br>
                Challenge.ON 2023 szervezői csapata</p>
            </div>
            <button class="btn btn-primary mt-3 float-end" @click="start">Elkezdjük a selejtezőt!</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Welcome',
  props: ['initData','contents'],
  data(){
    this.initData.bigImage = 1
    return{
      firstName : this.initData.user.firstName
    }
  },
  methods:{
    start(){
      this.initData.loader = true

      this.$axios.post('/api/v1/user/start')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.user.status = responseData.data.status
              this.initData.user.questionData = responseData.data.questionData
              this.initData.user.counter = responseData.data.counter
              this.initData.user.startDateTime = responseData.data.startDateTime
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }

}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,0.9);
}
</style>
