<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12" v-if="questionImage">
        <transition name="image-fade" mode="out-in">
          <img class="question-image" :src="questionImage" alt="" :key="questionImage"/>
        </transition>
        <span class="small text-muted">Selejtező végéig hátralévő idő</span>
        <div class="timer-block d-flex align-items-center">
          <div class="timer-display">
            {{this.counterDisplayTime}}
          </div>
          <div class="timer-bg">
            <div class="timer-line" :style="{width: Math.min((this.counterTime/21600)*100,100) + '%'}" :data-value="this.counterTime"></div>
          </div>
        </div>
        <span class="small text-muted">Elkezdve: {{startDateTime}}</span>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 col-sm-12">
        <h1>{{questionData.topicName}}</h1>
        <h2>{{questionData.taskName}}</h2>
        <div class="question" v-html="questionData.question">

        </div>
        <div class="answers">
          <template v-if="questionType===1">
            <div @click="selectAnswer" class="answer d-flex align-items-center" v-for="answer in questionData.answers" :data-hash="answer.hash" :key="answer.hash">
              <div class="checkmark"></div>
              <div class="close"></div>
              <div class="text">{{answer.answer}}</div>
            </div>
          </template>
        </div>

        <div class="col-12">
          <div class="row d-flex align-items-center">
            <div class="col-6 counter">
              {{ counterNow }} / {{counterAll}}
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button class="btn btn-primary" @click="answer" id="answerBtn">Mentés</button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Question',
  props: ['initData','contents'],
  data(){
    return {
      counterIsRunning : false,
      counterInterval : null,
      counterTime : 0,
      counterDisplayTime : '00:00:00'
    }
  },
  computed:{
    startDateTime(){
      return this.initData.user.startDateTime
    },
    questionData(){
      return this.initData.user.questionData
    },
    status(){
      let status = this.initData.user.status
      if(status === 'question'){
        this.initData.bigImage = null
      }
      return this.initData.user.status
    },
    questionType(){
      return this.questionData.type
    },
    questionImage(){
      let image = this.initData.user.questionData.image
      if(image===null){
        return false
      }
      let imagePath = ''
      if(isLocalHost===true){
        imagePath = '/img/tasks/'+image
      }else{
        imagePath = '/img/tasks/'+image
      }
      return imagePath
    },
    counterAll(){
      return this.initData.user.counter.all
    },
    counterNow(){
      return this.initData.user.counter.now
    },
    timeLimit(){
      let timeLimit = this.questionData.timeLimit
      //console.log(this.counterTime)

      if(this.counterTime<=0 && this.counterIsRunning===true){
        this.questionData.timeLimit = 0
      }
      if(timeLimit<=0 && this.counterIsRunning===true){
        console.log('automate submit answer')
        clearInterval(this.interval);
        this.counterIsRunning = false
        this.counterTime = 0
        sessionStorage.removeItem('challengeon_timer');

        if(timeLimit===0 || timeLimit===-1){
          this.eventFire(document.getElementById('answerBtn'), 'click');
        }

      }
      if(timeLimit>0 && this.counterIsRunning===false){
        let timeLimitFromSession = sessionStorage.getItem('challengeon_timer')
        if(timeLimitFromSession!==null && parseInt(timeLimitFromSession)<timeLimit && parseInt(timeLimitFromSession)>0){
          timeLimit = timeLimitFromSession
        }
        this.counterIsRunning = true
        this.counterTime = timeLimit
        this.counterDisplayTime = this.counterDisplay()
        this.toggleTimer()
      }
      return timeLimit
    }
  },
  watch:{
    status(){

    },
    timeLimit(){

    }
  },
  methods:{
    counterDisplay(){
      let counterDisplay = null;
      if(parseInt(this.counterTime)>59){
        let hours = Math.floor(this.counterTime/3600)
        let minutes = Math.floor((this.counterTime-(hours*3600))/60)
        let remainSeconds = this.counterTime-(hours*3600)-(minutes*60)
        counterDisplay =  (hours < 10 ? "0" : "") + String(hours) + ':' +
                          (minutes < 10 ? "0" : "") + String(minutes) + ':' +
                          (remainSeconds < 10 ? "0" : "") + String(remainSeconds)
      }else{
        counterDisplay = '00:00:' + (this.counterTime < 10 ? "0" : "") + String(this.counterTime)
      }
      return counterDisplay
    },
    toggleTimer() {
      this.interval = setInterval(this.decreasedTime, 1000);
    },
    decreasedTime() {
      this.counterTime = parseInt(this.counterTime) - 1;
      sessionStorage.setItem('citi_story_timer',this.counterTime);
      this.counterDisplayTime = this.counterDisplay()
      //console.log(this.counterDisplayTime)
    },
    eventFire(el, etype){
      if (el.fireEvent) {
        el.fireEvent('on' + etype);
      } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    },
    selectAnswer(e){
      if(this.questionType===1) {
        //SELECT
        let answerElement = e.target

        while (!answerElement.classList.contains('answer')) {
          answerElement = answerElement.parentNode
        }

        if(answerElement.classList.contains('selected')===true){
          answerElement.classList.remove('selected')
        }else{
          answerElement.classList.add('selected')
        }
      }
    },
    answer(){
      let answersHashes = []

      if(this.questionType===1){
        let answers = document.querySelectorAll('.answer.selected')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          answersHashes.push(hash)
        }
        if(this.counterTime>0 && answersHashes.length===0){
          this.initData.alerts.push({
            msg: 'Legalább egy választ ki kell jelölni.',
            type: 'error'
          })
          return false
        }
      }

      let postData = {
        'answersHashes' : answersHashes
      }

      this.initData.loader = true

      this.$axios.post('/api/v1/user/answer',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);
              clearInterval(this.interval);
              this.counterIsRunning = false
              this.counterTime = 0
              sessionStorage.removeItem('challengeon_timer');

              this.initData.user.status = responseData.data.status
              this.initData.user.questionData = responseData.data.questionData
              if(this.initData.user.status==='story-end'){
                console.log('story end')
                this.initData.user.ranking = responseData.data.ranking
              }else if(this.initData.user.status==='crossroad'){

              }else{
                this.initData.user.counter = responseData.data.counter
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
h1{
  color: var(--eon-red);
  font-size: 2rem;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}
h2{
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}
.question{
  background-color: var(--eon-red);
  color: #fff;
  border-radius: 24px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  font-weight: 700;
}
.question:deep(p:last-child){
  margin-bottom: 0;
}
.question-image{
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 24px;
}

.image-fade-enter-active{
  transition: opacity 2s ease-in-out;
}

.image-fade-enter-from{
  opacity: 0;
}

img.image-fade-enter-to{
  opacity: 1;
}

.answer{
  width: 100%;
  background: none;
  cursor: pointer;
  border: 1px solid #D8D8D8;
  padding: 18px 24px 18px 24px;
  line-height: 1.375rem;
  margin-bottom: 1rem;
}
.answer:hover,.answer.selected{
  background: #5cc1cb;
  color: #ffffff;
}
.text{
  padding-left: 24px;
}
.checkmark{
  display: block;
  position: relative;
  min-width: 1rem;
  min-height: 1rem;
  background-color: #E7E7E7;
}
.checkmark:after {
  display: none;
  content: "";
  position: absolute;
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #5cc1cb;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.answer:hover .checkmark:after,.answer.selected .checkmark:after{
  display: block;
}
.close{
  display: none;
  position: relative;
  min-width: 1rem;
  min-height: 1rem;
  background-color: #E7E7E7;
}
.close:after {
  display: block;
  content: "\274c";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0.7rem;
  line-height: 1rem;
  text-align: center;
}
.answer.selected:hover .close{
  display: block;
}
.answer.selected:hover .checkmark{
  display: none;
}
.timer-display{
  font-weight: 100;
  color: #00337F;
  padding-right: 1rem;
}

.timer-bg{
  width: 100%;
  padding: 1px;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
}

.timer-line{
  height: 8px;
  background-color: #009ADD;
  border-radius: 10px;
  transition: all 1s linear;
}
.counter{
  font-weight: 700;
  color: #00337F;
  line-height: 30px;
  text-align: left;
}
/* purgecss start ignore */

/* purgecss end ignore */
@media (max-width: 576px) {
  .timer-block{
    margin-bottom: 1rem;
  }
}
</style>
